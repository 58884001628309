import React, { useState } from "react";
import cn from "classnames";
import VacancyItem from "./vacancyItem";

const VacancySection = ({ vacancy, title }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((open) => !open);
  };

  return (
    <div className="vacancy__section">
      <div
        className={cn("vacancy__region-wrapper", {
          "vacancy__region-wrapper--active": open,
        })}
        onClick={handleClick}
      >
        <h3 className="vacancy__subtitle">{title}</h3>
        <button
          className={cn("vacancy__trigger", {
            "vacancy__trigger--active": open,
          })}
          type="button"
        />
      </div>
      <div className="vacancy__outer-wrapper">
        {vacancy ? vacancy.map((item) => <VacancyItem {...item} />) : <></>}
      </div>
    </div>
  );
};

export default VacancySection;
