import React, { useState } from "react";
import cn from "classnames";

const VacancyItem = ({ name, vacancies }) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    setOpen((open) => !open);
  };

  const month = [
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
  ];

  return (
    <div className="vacancy__section-wrapper">
      <h4 className="vacancy__section-row">
        <span
          className={cn("vacancy__section-name", {
            "vacancy__section-name--active": open,
          })}
          onClick={handleClick}
        >
          {name}
        </span>
        <span className="vacancy__section-count">{vacancies.length}</span>
      </h4>
      <div
        className={cn("vacancy__inner-wrapper", {
          "vacancy__inner-wrapper--active": open,
        })}
      >
        <table className="vacancy__table">
          <tbody>
            {vacancies.map((item) => (
              <tr>
                <td>
                  <p className="vacancy__name">{item.name}</p>
                  <p className="vacancy__date">
                    {new Date(item.published_at).getDate()}{" "}
                    {month[new Date(item.published_at).getMonth()]}
                  </p>
                </td>
                <td>
                  <p className="vacancy__cost">
                    {item.salary_from ? <>от {item.salary_from} {item.salary_currency === "RUR" ? "руб." : "тенге"}</> : ""}
                  </p>
                </td>
                <td>
                  <p className="vacancy__city">{item.city}</p>
                  <p className="vacancy__metro">{item.metro}</p>
                </td>
                <td>
                  <a href={item.url} className="vacancy__link">
                    Откликнуться
                  </a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default VacancyItem;
