import React from 'react';
import Lead from '../components/sections/lead';
import People from '../components/sections/people';
import Vacancy from '../components/sections/vacancy';
import Feedback from '../components/sections/feedback';
import Footer from '../components/sections/footer';
import leadVideo from '../resources/video/homepage.mp4';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const Career = () => {
	const { t } = useTranslation();

	return (
		<>
			<Helmet>
				<html lang="ru" />
				<title>{t('lead.seo.career')}</title>
			</Helmet>
			<Lead
				subtitle={t('lead.main-career.pre-title')}
				title={t('lead.main-career.main-title')}
				leadVideo={leadVideo}
				videoClass={`lead__video-wrapper--big`}
			/>
			<People />
			<Vacancy />
			<Feedback />
			<Footer />
		</>
	);
};

export default Career;
