import React from "react";

const People = () => {
  return (
    <section className="people" id="second">
      <div className="people__wrapper wrapper">
        <h2 className="people__title-text title-text">
          Наши люди — яркие и классные!
        </h2>
        <p className="people__lead-text big-text big-text--black">
          Наши ежедневные задачи —
          <span>амбициозные и масштабные проекты, </span>
          за которые возьмется не каждый.
        </p>
      </div>
    </section>
  );
};

export default People;
