import React, { useEffect, useState } from "react";
import axios from "axios";
import VacancySection from "./vacancySection";

const Vacancy = () => {
  const [vacancy, setVacancy] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://ntf.aquaart.tech/aquaart/vacancies")
      .then((response) => setVacancy(response.data))
      .finally(() => {
        setLoading(false);
      });
  }, []);

  if (loading) {
    return <p>Загружаем данные</p>;
  }

  return !!vacancy ? (
    <section className="vacancy">
      <div className="vacancy__wrapper wrapper">
        <h2 className="vacancy__title big-text big-text--black">
          Наши вакансии
        </h2>
        <VacancySection
          vacancy={vacancy.primary}
          title={`Северо-западный округ`}
        />
        <VacancySection
          vacancy={vacancy.others}
          title={`Вакансии в других регионах`}
        />
        <div className="vacancy__section">
          <p className="vacancy__others">
            Также вы можете прислать свое резюме на электронный адрес{" "}
            <a href="mailto:hr@aquaart.ru">hr@aquaart.ru</a> c указанием
            интересующей вас вакансии.
          </p>
        </div>
      </div>
    </section>
  ) : (
    <></>
  );
};

export default Vacancy;
