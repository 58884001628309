import React from 'react';
import { Link } from 'gatsby';
import { t } from 'i18next';

const Footer = () => {
	return (
		<footer className="footer">
			<div className="footer__wrapper wrapper">
				<nav className="footer__nav nav-links nav-links--black">
					<Link className="nav-link" to="/about-company/">
						{t('lead.navigation.aboutCompany')}
					</Link>
					<Link className="nav-link" to="/brands/">
						{t('lead.navigation.ourBrands')}
					</Link>
					<Link className="nav-link" to="/career/">
						{t('lead.navigation.career')}
					</Link>
					<Link className="nav-link" to="/contacts/">
						{t('lead.navigation.contact')}
					</Link>
					<Link className="nav-link" to="/policy/">
						{t('lead.navigation.policy')}
					</Link>
				</nav>
				<p className="footer__copyright">
					© {new Date().getFullYear()} {t('lead.navigation.copyright')}
				</p>
			</div>
		</footer>
	);
};

export default Footer;
